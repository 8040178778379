<template>
  <section id="section-feature-desc">
    <div class="section-feature-desc__wrap">
      <div class="section__head section-feature-desc__head">
        <h3>
          FEATURE
        </h3>
      </div>
      <div class="section-feature-desc__items">
        <div
          id="link__authentric"
          class="section-feature-desc__item section-feature-desc__item-1 section-feature-desc__item-left"
        >
          <div
            class="section-feature-desc__text section-feature-desc__text-right"
          >
            <h4>AUTHENTIC</h4>
            <p>
              本物へのこだわり。利用するサプリメントの素材・配合は科学論文や独自研究成果に基づき専門知識・経験を持つ研究者が度重なる検討を経て設計。全てにおいて妥協をしない、自信を持って提供する圧倒的組成。自由にサプリメントを配合できるGX
              FACTORYは、プロのニーズにも応えられるよう、1g単位で計量できる機構を備えた徹底的にこだわり抜いた性能を持つ。
            </p>
          </div>
        </div>
        <div
          id="link__functional"
          class="section-feature-desc__item section-feature-desc__item-2 section-feature-desc__item-right"
        >
          <div
            class="section-feature-desc__text section-feature-desc__text-left"
          >
            <h4>FUNCTIONAL</h4>
            <p>
              利便性の追求。毎日使うものだからこそ使いやすくしたい。GX
              FACTORYは日々のサプリメントの手間の解放をコンセプトに、オリジナルのサプリメントを自動で計量・配合して提供。GX
              PACK
              MINIはオールインワンをコンセプトに使い切りサイズで手軽に利用。パッケージデザインも今までにないデザインで仕事やプライベートにも違和感なく。
            </p>
          </div>
        </div>
        <div
          id="link__trust"
          class="section-feature-desc__item section-feature-desc__item-3 section-feature-desc__item-left"
        >
          <div
            class="section-feature-desc__text section-feature-desc__text-right"
          >
            <h4>TRUST</h4>
            <p>
              安心・安全・おいしさ。これらはキリンのDNA。数々の商品開発の歴史があるからこその品質。多くのサプリメントを利用しているプロの方を含め数々の試作・試食を繰り返し、おいしさにも徹底的なこだわりを。
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style>
#section-feature-desc {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  padding: 70px 0 70px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.section-feature-desc__wrap {
  width: 100%;
  max-width: 1020px;
}

.section-feature-desc__text h4::before {
  margin-right: 30px;
}

.section-feature-desc__text h4::after {
  margin-left: 30px;
}

.section-feature-desc__item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  height: 520px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 30px auto;
}

.section-feature-desc__item-1 {
  background-image: url("../../assets/lp_new/feature1.png");
}

.section-feature-desc__item-2 {
  background-image: url("../../assets/lp_new/feature2.png");
}

.section-feature-desc__item-3 {
  background-image: url("../../assets/lp_new/feature3.png");
}

.section-feature-desc__item-right {
  background-position: 100%;
}

.section-feature-desc__text {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 50px 50px;
  border-radius: 25px;
  width: 60%;
}

.section-feature-desc__text-right {
  margin-left: auto;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.section-feature-desc__text-left {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.section-feature-desc__text h4 {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  margin-bottom: 30px;
  font-size: 32px;
  font-weight: bold;
  color: #a69c7d;
}

.section-feature-desc__text h4::after,
.section-feature-desc__text h4::before {
  content: "";
  background-image: url("../../assets/lp_new/section_head_item.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 20px;
  height: 30px;
  display: block;
}

.section-feature-desc__text p {
  font-size: 16px;
  line-height: 2.5;
  text-shadow: 2px 1px 1px rgb(0 0 0 / 30%);
}

@media screen and (max-width: 1020px) {
  .section-feature-desc__wrap {
    max-width: 700px;
  }

  .section-feature-desc__item {
    height: 380px;
  }
}

@media screen and (max-width: 768px) {
  #section-feature-desc {
    padding: 30px 0 30px;
  }

  .section-feature-desc__wrap {
    padding: 0 24px;
  }

  .section-feature-desc__item {
    height: 310px;
  }

  .section-feature-desc__item-left {
    background-position: 35%;
  }

  .section-feature-desc__item-right {
    background-position: 65%;
  }

  .section-feature-desc__text h4 {
    font-size: 14px;
  }

  .section-feature-desc__text h4::after,
  .section-feature-desc__text h4::before {
    content: "";
    background-image: url("../../assets/lp_new/section_head_item.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 12px;
    height: 18px;
    display: block;
  }

  .section-feature-desc__text p {
    font-size: 12px;
    line-height: 1.5;
  }

  .section-feature-desc__text {
    padding: 30px 20px;
  }
}

@media screen and (max-width: 480px) {
}
</style>
